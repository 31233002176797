<template>
  <div class="p-5 mt-5">
    Those are the magic projects with custom webapps:
    <div v-if="getWebappValue(proj)" class="mb-2" :key="proj.id" v-for="proj in projects">
      {{ $store.getters.getOrganizationDisplayName(proj.organizationId) }}<br />
      <span class="lighter"><router-link :to="'/project/' + proj.id + '/general'">{{ proj.name }}</router-link></span><br />
      <div v-if="getWebappValue(proj)">
        Webapp: <router-link :to="'/app/' + getWebappValue(proj) + '/general'">{{ $store.getters.getAppName(getWebappValue(proj)) }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
const metaFieldId = 'c1fcb25f-71a7-4fc6-b78b-b5ea9dc2c615';
export default {
  name: 'LinkedWebapps',
  data() {
    return {
      projects: []
    };
  },
  beforeMount() {
    this.$store.dispatch('loadApps');
    this.$store.dispatch('clientLoadProjects', {
      filter: 'type eq vstage',
      include: ['metaValues']
    }).then(projects => {
      this.projects = projects;
    })
  },
  methods: {
    getWebappValue(project) {
      const {metaValues} = project;
      if(metaValues) {
        const index = metaValues.findIndex(item => {
          return item.metaFieldId === metaFieldId;
        })
        if(index !== -1) {
          const {value} = metaValues[index];
          return value;
        }
      }
      return null;
    },
  }
}
</script>